import { Navigate } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import ManageAddProviderForm from '../pages/ManageUsers/ManageProvider/ManageAddProviderForm';
import ManageProvider from '../pages/ManageUsers/ManageProvider/ManageProvider';
import ManageUser from '../pages/ManageUsers/ManageUser/ManageUser';
import ManageUserAddForm from '../pages/ManageUsers/ManageUser/ManageUserAddForm';
// import Health from "../pages/Cms/Health/Health";
import Qualification from '../pages/Cms/Qualification/Qualification';
import Skill from '../pages/Cms/Skill/Skill';
import Specialization from '../pages/Cms/Specialization/Specialization';
import Pending from '../pages/ManageBooking/Pending';
import Upcoming from '../pages/ManageBooking/Upcoming';
import Complete from '../pages/ManageBooking/Complete';
import Ongoing from '../pages/ManageBooking/Ongoing';
import Reject from '../pages/ManageBooking/Reject';
import Accept from '../pages/ManageBooking/Accept';
import ManageCard from '../pages/ManagePayment/ManageCard';
import ManageInsurance from '../pages/ManagePayment/ManageInsurance';
import Term from '../pages/Static/T&C/Term';
import Privacy from '../pages/Static/P&P/Privacy';
import UpdateTerm from '../pages/Static/T&C/UpdateTerm';
import UpdatePrivacy from '../pages/Static/P&P/UpdatePrivacy';
import PostCondation from '../pages/Static/PostCondation';
import Transections from '../pages/Transections/Transections';
import ViewTc from '../pages/Static/T&C/ViewTc';
import ViewPp from '../pages/Static/P&P/ViewPp';
import Payout from '../pages/Payout/Payout';
import Fields from '../pages/BookingField/fields';
import ManageFieldAddForm from '../pages/BookingField/addField';
import ManageFieldEdit from '../pages/BookingField/editField';
import Chronic from '../pages/Cms/Chronic/Chronic';

const MainRoute = [
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/user/manage-user', element: <ManageUser /> },
  { path: '/booking/manage-booking-field', element: <Fields /> },
  { path: '/booking/add-booking-field', element: <ManageFieldAddForm /> },
  { path: '/booking/edit-booking-field', element: <ManageFieldEdit /> },
  { path: '/user/user-add-form', element: <ManageUserAddForm /> },
  { path: '/user/manage-provider', element: <ManageProvider /> },
  { path: '/user/provider-add-form', element: <ManageAddProviderForm /> },
  { path: '/booking/complete', element: <Complete /> },
  { path: '/booking/upcoming', element: <Upcoming /> },
  { path: '/booking/Accept', element: <Accept /> },
  { path: '/booking/reject', element: <Reject /> },
  { path: '/booking/ongoing', element: <Ongoing /> },
  { path: '/booking/pending', element: <Pending /> },
  { path: '/payment/card', element: <ManageCard /> },
  { path: '/payment/insurance', element: <ManageInsurance /> },
  // { path: "/cms/health", element: <Health /> },
  { path: '/cms/chronic', element: <Chronic /> },
  { path: '/cms/specialzation', element: <Specialization /> },
  { path: '/cms/skill', element: <Skill /> },
  { path: '/cms/qualification', element: <Qualification /> },
  { path: '/static/post-condation', element: <PostCondation /> },
  { path: '/static/term-and-condation', element: <Term /> },
  { path: '/static/update-term-and-condation', element: <UpdateTerm /> },
  { path: '/static/privacy-policy', element: <Privacy /> },
  { path: '/static/update-privacy-policy', element: <UpdatePrivacy /> },
  { path: '/view-privacy-policy', element: <ViewPp /> },
  { path: '/view-term-condation', element: <ViewTc /> },
  { path: '/transaction', element: <Transections /> },
  { path: '/payout', element: <Payout /> },
  { path: '*', element: <Navigate to='/dashboard' replace /> },
];
export default MainRoute;
